import "./SaveDetailPage.css";
import navback from "../../img/navback.svg";

const SaveDetailPage = () => {
    return (
        <div className="savedetailmainframe">
            <div className="savadetailtxtframe">
                <div className="savedetailtxt2">보던 페이지로 돌아가는 중.....</div>
                <div className="savedetailtxt1">목록에 저장이 완료되었습니다!</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaveDetailPage;
