import './SerchMuseumPerformPage.css';
import navback from '../../img/navback.svg';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const SerchMuseumPerformPage = () => {
    const { facilityId } = useParams();
    const [facilityName, setFacilityName] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [performances, setPerformances] = useState([]);

    // 공연장 상세 정보 가져오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                // 공연장 정보 가져오기
                const facilityResponse = await fetch(`http://co.mayeon.kr:8081/culture/facility/detail/${facilityId}`);
                const facilityData = await facilityResponse.json();
                setFacilityName(facilityData.fcltynm);

                // 해당 공연장의 공연 목록 가져오기
                const performanceResponse = await fetch(
                    `http://co.mayeon.kr:8081/culture/performance/list?cpage=1&rows=10&stdate=20241101&eddate=20251231&prfplccd=${facilityId}`
                );
                const performanceData = await performanceResponse.json();

                console.log('공연 데이터:', performanceData);
                setPerformances(performanceData);
            } catch (error) {
                console.error('데이터 가져오기 오류:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [facilityId]);

    const handlePerformanceClick = (performanceId) => {
        navigate(`/serchdetailpage/${performanceId}`);
    };

    if (loading) {
        return <div className="loading">로딩 중...</div>;
    }

    return (
        <div className="sermuspermainframe">
            <div className="sermusper-content">
                <h2 className="facility-name">{facilityName} 공연 목록</h2>

                {performances.length === 0 ? (
                    <div className="no-performances">현재 예정된 공연이 없습니다.</div>
                ) : (
                    <div className="performance-list">
                        {performances.map((performance, index) => (
                            <div
                                key={performance.mt20id}
                                className="performance-item"
                                onClick={() => handlePerformanceClick(performance.mt20id)}
                            >
                                <img src={performance.poster} alt={performance.prfnm} className="performance-poster" />
                                <div className="performance-info">
                                    <h3 className="performance-title">{performance.prfnm}</h3>
                                    <p>
                                        기간: {performance.prfpdfrom} ~ {performance.prfpdto}
                                    </p>
                                    <p>위치: {facilityName}</p>
                                    <button className="detail-button">상세보기</button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="navbar">
                <img className="navback-icon" alt="뒤로가기" src={navback} onClick={() => navigate(-1)} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SerchMuseumPerformPage;
