import './DeletePeoplePage.css';
import navback from '../../img/navback.svg';

const DeletePeoplePage = () => {
    return (
        <div className="deletepeoplemainframe">
            <div className="deletepeoplesubframe">
                <div className="deletepeoplesubtxt">
                    <p className="p">커뮤니티 글쓰기 기능</p>
                    <p className="p">커뮤니티 댓글 쓰기 기능</p>
                    <p className="p">&nbsp;</p>
                    <p className="p">그 외 여러가지....</p>
                </div>
                <div className="deletepeoplemaintxt2">탈퇴 후엔 아래 기능을 사용할 수 없게 됩니다!</div>
                <div className="deletepeoplemaintxt1">정말 가시려고요?</div>
                <div className="deletepeoplebutton">
                    <div className="deletepeoplebuttonframe">
                        <b className="deletepeoplebuttontxt">탈퇴</b>
                    </div>
                </div>
                <div className="deletepeopleignorebutton">
                    <div className="deletepeoplebuttonframe">
                        <div className="deletepeoplebuttontxt">탈퇴취소</div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeletePeoplePage;
