import './CommunityMakePage.css';
import navback from '../../img/navback.svg';

const CommunityMakePage = () => {
    return (
        <div className="commakepageframe">
            <div className="commakepagebuttonframe">
                <div className="commakeprofitbutton">
                    <div className="commakeprofitbuttonframe">
                        <b className="commakeprofitbuttonframetxt">작성</b>
                    </div>
                </div>
                <div className="commakecancelbutton">
                    <div className="commakeprofitbuttonframe">
                        <b className="commakeprofitbuttonframetxt">취소</b>
                    </div>
                </div>
            </div>
            <div className="commainpageinputframe">
                <div className="commainpagetitleinput">
                    <div className="commainpagetitleinputlabel">제목 :</div>
                    <div className="commainpagetitleinputframe">
                        <div className="commainpagetitleinputframetxt">Value</div>
                    </div>
                </div>
                <div className="commainpagedetailinput">
                    <div className="commainpagetitleinputlabel">{`내용 : `}</div>
                    <div className="commainpagetitleinputframe">
                        <div className="commainpagetitleinputframetxt">Value</div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunityMakePage;
