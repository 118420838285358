import './RebuildPeople.css';
import navback from '../../img/navback.svg';

const RebuildPeople = () => {
    return (
        <div className="rebpeomainframe">
            <div className="rebpeomainform">
                <div className="rebpeomainformbg" />
                <div className="rebpeoconfirmbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeoconfirmbuttontxt">정보 수정 완료</div>
                    </div>
                </div>
                <div className="rebpeopnumbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeopnumbuttontxt">Phone Number</div>
                    </div>
                </div>
                <div className="rebpeopnumtxt">휴대폰 번호 수정</div>
                <div className="rebpeobirthbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeopnumbuttontxt">BirthDate</div>
                    </div>
                </div>
                <div className="rebpeobirthtxt">생년월일 수정</div>
                <div className="rebpeonamebutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeopnumbuttontxt">name</div>
                    </div>
                </div>
                <div className="rebpeonametxt">이름 입력</div>
                <div className="rebpeopwcbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeopnumbuttontxt">PW check</div>
                    </div>
                </div>
                <div className="rebpeopwctxt">비밀번호 확인</div>
                <div className="rebpeopwbutton">
                    <div className="rebpeoconfirmbuttonframe">
                        <div className="rebpeopnumbuttontxt">PW</div>
                    </div>
                </div>
                <div className="rebpeopwtxt">변경할 비밀번호</div>
                <div className="rebpeomaintxt">정보 수정</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RebuildPeople;
