import './CreatePeopleAfter.css';
import navback from '../../img/navback.svg';

const CreatePeopleAfter = () => {
    return (
        <div className="crepeomainafterframe">
            <div className="crepeoafterframe">
                <div className="crepeoaftermaintxt3">메인으로 돌아가는 중.....</div>
                <div className="crepeoaftermaintxt2">안녕하세요 윤영빈님! 반갑습니다!</div>
                <div className="crepeoaftermaintxt1">회원가입이 완료되었습니다!</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePeopleAfter;
