import './SerchMuseumPage.css';
import navback from '../../img/navback.svg';
import pinmuseum from '../../img/pinmuseum.png';
import seoulmap from '../../img/seoulmap.png';
import arrowdropdown from '../../img/arrowdropdown.svg';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SerchMuseumPage = () => {
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [facilities, setFacilities] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/culture/code/city');
                const data = await response.json();
                setCities(data);
            } catch (error) {
                console.error('Error fetching cities', error);
            }
        };
        fetchCities();
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const fetchDistricts = async () => {
                try {
                    const response = await fetch(`http://co.mayeon.kr:8081/culture/code/district/${selectedCity}`);
                    const data = await response.json();
                    setDistricts(data);
                } catch (error) {
                    console.error('Error fetching districts', error);
                }
            };
            fetchDistricts();
        } else {
            setDistricts([]);
        }
    }, [selectedCity]);

    const fetchFacilities = async () => {
        try {
            // 기본 URL 파라미터 설정
            const params = new URLSearchParams({
                cpage: '1',
                rows: '10',
                signgucode: selectedCity,
            });

            // 군구가 선택되었다면 districtcode 파라미터 추가
            if (selectedDistrict) {
                params.append('signgucodesub', selectedDistrict);
            }

            const url = `http://co.mayeon.kr:8081/culture/facility/list?${params.toString()}`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('서버 응답에 문제가 있습니다.');
            }

            const data = await response.json();

            if (!data || data.length === 0) {
                setFacilities([]);
                return;
            }

            setFacilities(data);

            console.log('제데로 들어오닝', data);
        } catch (error) {
            console.error('Error fetching facilities:', error);
            setFacilities([]);
        }
    };

    const handleSearch = () => {
        fetchFacilities();
    };

    const handleDetailClick = (facilityId) => {
        navigate(`/serchmuseumdetailpage/${facilityId}`);
    };

    const renderFacilities = () => {
        if (!facilities || facilities.length === 0) {
            return <div className="no-results">공연장이 없습니다.</div>;
        }

        return facilities.map((facility, index) => {
            const topPosition = 3.688 + index * 10;

            return (
                <div key={index} className="sermusres1frame" style={{ top: `${topPosition}rem` }}>
                    <div className="sermusres1title">{facility.fcltynm}</div>
                    <div className="sermusres1detbutton" onClick={() => handleDetailClick(facility.mt10id)}>
                        <div className="sermusres1detbuttonframe">
                            <b className="sermusres1detbuttontxt">상세보기</b>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="sermusmainframe">
            <div className="sermusresmainframe">{renderFacilities()}</div>
            <div className="sermusmapframe">
                <img className="sermusmainmap-icon" alt="" src={seoulmap} />
                <img className="sermussubpin3-icon" alt="" src={pinmuseum} />
                <img className="sermussubpin2-icon" alt="" src={pinmuseum} />
                <img className="sermussubpin1-icon" alt="" src={pinmuseum} />
            </div>
            <div className="serchmusbar">
                <div className="sermussigroup">
                    <select
                        className="sermussiserch"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                    >
                        <option value="">시/도 선택</option>
                        {cities.map((city) => (
                            <option key={city.code} value={city.code}>
                                {city.name}
                            </option>
                        ))}
                    </select>
                    <img className="sermussiarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="sermusgugroup">
                    <select
                        className="sermusguserch"
                        value={selectedDistrict}
                        onChange={(e) => setSelectedDistrict(e.target.value)}
                        disabled={!selectedCity}
                    >
                        <option value="">군/구 선택</option>
                        {districts.map((district) => (
                            <option key={district.code} value={district.code}>
                                {district.name}
                            </option>
                        ))}
                    </select>
                    <img className="sermusguarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="sermusmainbutton" onClick={handleSearch}>
                    <div className="sermusres1detbuttonframe">
                        <b className="sermusres1detbuttontxt">검색</b>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SerchMuseumPage;
