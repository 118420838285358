import './SerchPosterPage.css';
import navback from '../../img/navback.svg';
import arrowdropdown from '../../img/arrowdropdown.svg';
import cross from '../../img/cross.svg';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const SerchPosterPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [genres, setGenres] = useState([]);
    const [posters, setPosters] = useState([]);
    //const [currentPage, setCurrentPage] = useState(1);
    //const [postersPerPage, setPostersPerPage] = useState(20);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/culture/code/city');
                const data = await response.json();
                setCities(data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };
        fetchCities();
    }, []);

    useEffect(() => {
        const fetchGenres = async () => {
            try {
                const response = await fetch('http://co.mayeon.kr:8081/culture/code/genre');
                const data = await response.json();
                setGenres(data);
            } catch (error) {
                console.error('Error fetching genres:', error);
            }
        };
        fetchGenres();
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const fetchDistricts = async () => {
                try {
                    const response = await fetch(`http://co.mayeon.kr:8081/culture/code/district/${selectedCity}`);
                    const data = await response.json();
                    setDistricts(data);
                } catch (error) {
                    console.error('Error fetching districts:', error);
                }
            };
            fetchDistricts();
        }
    }, [selectedCity]);

    const fetchPerformances = async () => {
        try {
            const response = await fetch(
                `http://co.mayeon.kr:8081/culture/performance/list?cpage=1&rows=10&stdate=20241101&eddate=20251231&signgucode=${selectedCity}&signgucodesub=${selectedDistrict}&shcate=${selectedGenre}`
            );
            const data = await response.json();
            setPosters(data);
        } catch (error) {
            console.error('Error fetching performances:', error);
        }
    };

    const handleSearch = () => {
        fetchPerformances();
    };

    /*
const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setPostersPerPage(parseInt(selectedValue));
        setCurrentPage(1); // 페이지 번호 초기화
        fetchPerformances();
    };

    const indexOfLastPoster = currentPage * postersPerPage;
    const indexOfFirstPoster = indexOfLastPoster - postersPerPage;
    const currentPosters = posters.slice(indexOfFirstPoster, indexOfLastPoster);
    const totalPages = Math.ceil(posters.length / postersPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
        */

    return (
        <div className="serposmainframe">
            <div className="poster-results">
                {posters.map((poster, index) => (
                    <div key={index} className="serchposterview">
                        <img className="serposimg-icon" alt="" src={poster.poster} />
                        <b className="serpostit">{poster.prfnm}</b>
                        <b className="serposper">기간: {poster.prfpdto}</b>
                        <b className="serposdes">위치: {poster.fcltynm}</b>

                        <Link to={`/serchdetailpage/${poster.mt20id}`}>
                            <div className="serposviewdetail5button">
                                <div className="serposviewdetail5buttonframe">
                                    <b className="serposviewdetail5buttontxt">상세보기</b>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            <div className="serchposterbar">
                <div className="serposstringserch">
                    <input
                        type="text"
                        className="serposstringtxt"
                        placeholder="검색어를 입력해주세요"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <img className="serposstringcancel-icon" alt="" src={cross} onClick={() => setSearchTerm('')} />
                </div>
                {/*
                    <div className="serposlistnumgroup">
                    <select
                        onChange={(e) => setPostersPerPage(e.target.value)}
                        defaultValue="10"
                        className="serposlistnumserch"
                    >
                        <option value="5">5개</option> <option value="10">10개</option>
                        <option value="15">15개</option> <option value="20">20개</option>
                        <option value="50">50개</option> <option value="100">100개</option>
                    </select>
                    </div>
                    */}

                <div className="serpossigroup">
                    <select
                        className="serpossiserch"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                    >
                        <option value="">시/도 선택</option>
                        {cities.map((city) => (
                            <option key={city.code} value={city.code}>
                                {city.name}
                            </option>
                        ))}
                    </select>
                    <img className="serpossiarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serposgugroup">
                    <select
                        className="serpossiserch"
                        value={selectedDistrict}
                        onChange={(e) => setSelectedDistrict(e.target.value)}
                        disabled={!selectedCity}
                    >
                        <option value="">군/구 선택</option>
                        {districts.map((district) => (
                            <option key={district.code} value={district.code}>
                                {district.name}
                            </option>
                        ))}
                    </select>
                    <img className="serposguarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serposgengroup">
                    <select
                        className="serpossiserch"
                        value={selectedGenre}
                        onChange={(e) => setSelectedGenre(e.target.value)}
                    >
                        <option value="">장르 선택</option>
                        {genres.map((genre) => (
                            <option key={genre.code} value={genre.code}>
                                {genre.name}
                            </option>
                        ))}
                    </select>
                    <img className="serposguarrow-icon" alt="" src={arrowdropdown} />
                </div>
                <div className="serposprofitbutton" onClick={handleSearch}>
                    <div className="serposviewdetail5buttonframe">
                        <b className="serposviewdetail5buttontxt">검색</b>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
            {/*
                <div className="pagination-container">
                <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    이전
                </button>
                <span>
                    {currentPage} / {totalPages}
                </span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    다음
                </button>
            </div>
        </div>
        */}
        </div>
    );
};

export default SerchPosterPage;
