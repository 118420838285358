import React from 'react';
import './CommunityDetailPage.css';
import navback from '../../img/navback.svg';

const CommunityDetailPage = () => {
    return (
        <div className="comdetailpageframe">
            <div className="comdetailsubresult">
                <b className="comdetailsubresultwritter">이영재</b>
                <b className="comdetailsubresultdate">2024-09-29</b>
                <div className="comdetailsubresultdetail">{`-> 좋은정보 감사합니다!`}</div>
                <div className="comdetailsubresultbuttonframe">
                    <div className="comsubdelbutton">
                        <div className="comsubdelbuttonframe">
                            <b className="comsubdelbuttontxt">삭제</b>
                        </div>
                    </div>
                    <div className="comsubsubmitbutton">
                        <div className="comsubdelbuttonframe">
                            <b className="comsubdelbuttontxt">수정</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="comdetailsubinputframe">
                <div className="comdetailsubinput">
                    <div className="comdetailsubinputlabel">댓글 1개 :</div>
                    <div className="comdetailsubinputframe1">
                        <div className="comdetailsubinputtxt">댓글</div>
                    </div>
                </div>
                <div className="comdetailsubsumitbutton">
                    <div className="comsubdelbuttonframe">
                        <b className="comsubdelbuttontxt">작성</b>
                    </div>
                </div>
            </div>
            <div className="comdetailmainframe">
                <b className="comdetailwrittertxt">윤영빈</b>
                <b className="comdetaildatetxt">2024-09-29</b>
                <b className="comdetailtitletxt">해치마당 공연 추천드려요~!</b>
                <div className="comdetailsubresultdetail">
                    해치마당에서는 한국 전통 악기 공연부터 현대 무용, 버스킹까지 다양한 장르의 공연이 열리며, 입장료는
                    무료로 부담 없이 방문할 수 있어요! 아이들과 함께 나들이하기에도 좋은 장소라 가족 단위의 방문객에게
                    특히 추천드립니다. 또한, 야외에서 펼쳐지는 공연이라 날씨가 좋은 가을에 딱 맞는 나들이 코스가 될
                    거예요!
                </div>
                <div className="comdetailmainbuttonframe">
                    <div className="comdetaildelbutton">
                        <div className="comsubdelbuttonframe">
                            <b className="comsubdelbuttontxt">삭제</b>
                        </div>
                    </div>
                    <div className="comdetaileditbutton">
                        <div className="comsubdelbuttonframe">
                            <b className="comsubdelbuttontxt">수정</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunityDetailPage;
