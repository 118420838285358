import "./SerchDetailMapPage.css";
import navback from "../../img/navback.svg";
import image1 from "../../img/image1.png";
import pinsleep from "../../img/pinsleep.png";
import pinrestaurant from "../../img/pinrestaurant.png";
import seoulmuseummap from "../../img/seoulmuseummap.png";

const SerchDetailMapPage = () => {
    return (
        <div className="serdetmapmainframe">
            <div className="serdetmapframe">
                <img className="serdetmapmainmap-icon" alt="" src={seoulmuseummap} />
                <img className="serdetmaprespin1-icon" alt="" src={pinrestaurant} />
                <img className="serdetmapaccompin1-icon" alt="" src={pinsleep} />
                <img className="serdetmapaccompin2-icon" alt="" src={pinsleep} />
                <img className="serdetmapaccompin3-icon" alt="" src={pinsleep} />
                <div className="serdetmapresgroup1">
                    <img className="serdetmaprespin2-icon" alt="" src={pinrestaurant} />
                    <div className="serdetmapdetframe">
                        <div className="div">
                            <p className="p">
                                <b>매드포갈릭</b>
                            </p>
                            <p className="p1">서울특별시 중구 동호로 15길 31</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="serdetmapsubframe">
                <div className="serdetmapdesframe">
                    <b className="serdetmaptitletxt">해치마당 미디어월</b>
                    <div className="serdetmapgenretxt">장르 : 공연</div>
                    <div className="serdetmapscaletxt">규모 : 야외 공연</div>
                    <div className="serdetmappnumtxt">전화 : 02-1234-1234</div>
                    <div className="serdetmapperiodtxt">기간 : 24.07.01-24.09.30</div>
                    <div className="serdetmapdestxt">위치 : 광화문 광장</div>
                    <div className="serdetmapruntimetxt">공연 런타임 : 1시간 30분</div>
                    <div className="serdetmapagetxt">관람 연령 : 전체이용가</div>
                    <div className="serdetmappricetxt">티켓가격 : 무료</div>
                    <div className="serdetmapstorytxt">
                        줄거리 : 형형색색의 다채로운 색감을 느낄수 있는 마법같은 공연
                    </div>
                    <div className="serdetmapdesalign" />
                </div>
                <img className="serdetmapdesimg-icon" alt="" src={image1} />
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SerchDetailMapPage;
