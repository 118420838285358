import './CreatePeople.css';
import navback from '../../img/navback.svg';

const CreatePeople = () => {
    return (
        <div className="crepeomainframe">
            <div className="crepeomainform">
                <div className="crepeomainformbg" />
                <div className="crepeoconfirmbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeoconfirmbuttontxt">회원가입 완료</div>
                    </div>
                </div>
                <div className="crepeopnumbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeopnumbuttontxt">Phone Number</div>
                    </div>
                </div>
                <div className="crepeopnumtxt">휴대폰 번호</div>
                <div className="crepeobirthbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeopnumbuttontxt">BirthDate</div>
                    </div>
                </div>
                <div className="crepeobirthtxt">생년월일 입력</div>
                <div className="crepeonamebutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeopnumbuttontxt">name</div>
                    </div>
                </div>
                <div className="crepeonametxt">이름 입력</div>
                <div className="crepeopwcbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeopnumbuttontxt">PW check</div>
                    </div>
                </div>
                <div className="crepeopwctxt">비밀번호 확인</div>
                <div className="crepeopwbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeopnumbuttontxt">PW</div>
                    </div>
                </div>
                <div className="crepeopwtxt">비밀번호 입력</div>
                <div className="crepeoidbutton">
                    <div className="crepeoconfirmbuttonframe">
                        <div className="crepeopnumbuttontxt">ID</div>
                    </div>
                </div>
                <div className="crepeoidtxt">아이디 입력</div>
                <div className="crepeomaintxt">회원가입</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePeople;
