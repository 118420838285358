import './MyListEditPage.css';
import image1 from '../../img/image1.png';
import image2 from '../../img/image2.png';
import navback from '../../img/navback.svg';
import close from '../../img/close.svg';

const Frame = () => {
    return (
        <div className="mylisteditframe">
            <div className="mylisteditbuttonframe">
                <div className="mylisteditcompletebutton">
                    <div className="mylisteditcompletebuttonframe">
                        <b className="mylisteditcompletebuttontxt">목록 수정완료</b>
                    </div>
                </div>
                <div className="mylisteditcancelbutton">
                    <div className="mylisteditcompletebuttonframe">
                        <b className="mylisteditcompletebuttontxt">목록 수정취소</b>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
            <div className="mylistframe1">
                <img className="mylistframeimg1-icon" alt="" src={image1} />
                <div className="mylistframetxt1">해치마당 미디어월</div>
                <div className="mylistframe1button">
                    <div className="mylisteditcompletebuttonframe">
                        <b className="mylisteditcompletebuttontxt">상세보기</b>
                    </div>
                </div>
                <img className="close-icon" alt="" src={close} />
            </div>
            <div className="mylistframe2">
                <img className="mylistframeimg2-icon" alt="" src={image2} />
                <div className="mylistframetxt1">광장으로의 초대</div>
                <div className="mylistframe1button">
                    <div className="mylisteditcompletebuttonframe">
                        <b className="mylisteditcompletebuttontxt">상세보기</b>
                    </div>
                </div>
                <img className="close-icon" alt="" src={close} />
            </div>
        </div>
    );
};

export default Frame;
