import './MyListNotHavePage.css';
import navback from '../../img/navback.svg';

const MyListNotHavePage = () => {
    return (
        <div className="mylistnothavepageframe">
            <div className="mylistnothavepagemainframe">
                <div className="mylistnothavepagegotomypagebut">
                    <div className="mylistnothavepagegotomypagebut1">
                        <div className="mylistnothavepagegotomypagebut2">마이페이지로 돌아가기</div>
                    </div>
                </div>
                <div className="mylistnothavepagemaintxt">앗! 목록이 비었어요!</div>
                <div className="mylistnothavepagesubtxt">
                    <p className="null">우리 트래픽이 가져오려 하였으나 NULL밖에 없다네요!</p>
                    <p className="null">목록을 채우고 다시 시도해주세요!</p>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyListNotHavePage;
