import './Mypage.css';
import navback from '../../img/navback.svg';

const Mypage = () => {
    return (
        <div className="mypageframe">
            <div className="mypagemainframe">
                <div className="mypageinputgroup">
                    <div className="mypageframebg" />
                    <div className="mypagesubtxt">안녕하세요! 윤영빈님!</div>
                    <div className="mypagelistbutton">
                        <div className="mypagelistbuttonframe">
                            <div className="mypagelistbuttontxt">찜한 목록 조회</div>
                        </div>
                    </div>
                    <div className="mypagemodifybutton">
                        <div className="mypagelistbuttonframe">
                            <div className="mypagelistbuttontxt">개인정보 수정</div>
                        </div>
                    </div>
                    <div className="mypageexitbutton">
                        <div className="mypagelistbuttonframe">
                            <div className="mypagelistbuttontxt">회원탈퇴</div>
                        </div>
                    </div>
                </div>
                <div className="mypagemaintxt">마이페이지</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mypage;
