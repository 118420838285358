import './LoginedMain.css';
import image1 from '../../img/image1.png';
import image2 from '../../img/image2.png';
import image3 from '../../img/image3.png';
import lefticon from '../../img/lefticon.svg';
import righticon from '../../img/righticon.svg';
import logo from '../../img/logo.svg';
import logoletter from '../../img/logoletter.svg';
import navback from '../../img/navback.svg';

const LoginedMain = () => {
    return (
        <div className="mainframe">
            <div className="mainframe2">
                <div className="subframegroup">
                    <div className="groupbg" />
                    <div className="titletxt">이번달 예술공연 한눈에 보기</div>
                    <div className="subshowtxt1-parent">
                        <div className="subshowtxt1">해치마당 미디어월</div>
                        <img className="subshowimg1-icon" alt="" src={image1} />
                    </div>
                    <div className="subshowtxt2-parent">
                        <div className="subshowtxt1">광장으로의 초대</div>
                        <img className="subshowimg2-icon" alt="" src={image2} />
                    </div>
                    <div className="subshowtxt3-parent">
                        <div className="subshowtxt1">우주 엘레베이터</div>
                        <img className="subshowimg3-icon" alt="" src={image3} />
                    </div>
                </div>
                <div className="leftback" />
                <div className="rightback" />
                <img className="lefticon" alt="" src={lefticon} />
                <img className="righticon" alt="" src={righticon} />
            </div>
            <div className="mainframe1">
                <div className="mainframe1back" />
                <img className="sublogo-icon" alt="" src={logoletter} />
                <img className="mainshowimg1-icon" alt="" src={image1} />
                <img className="mainshowimg2-icon" alt="" src={image2} />
                <img className="mainlogo-icon" alt="" src={logo} />
                <div className="navbar">
                    <img className="navback-icon" alt="" src={navback} />
                    <div className="navlink">
                        <div className="navlinkhome">
                            <div className="home">Home</div>
                        </div>
                        <div className="navlinkhome">
                            <div className="home">정보</div>
                        </div>
                        <div className="navlinkhome">
                            <div className="home">커뮤니티</div>
                        </div>
                    </div>
                    <b className="logoutbutton">로그아웃</b>
                    <b className="mypagebutton">마이페이지</b>
                </div>
            </div>
        </div>
    );
};

export default LoginedMain;
