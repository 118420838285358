import './CommunityMainPage.css';
import navback from '../../img/navback.svg';

const CommunityMainPage = () => {
    return (
        <div className="commainpageframe">
            <div className="commainmakebutton">
                <div className="commainmakebuttonframe">
                    <b className="commainmakebuttontxt">글쓰기</b>
                </div>
            </div>
            <div className="commaintitleframe">
                <div className="commaintitleframeviews">조회수</div>
                <div className="commaintitleframetitle">제목</div>
            </div>
            <div className="comsubtitleframe1">
                <div className="commaintitleframeviews">105</div>
                <div className="comsubframetitle1">해치마당 공연 추천드려요~!</div>
            </div>
            <div className="comsubtitleframe2">
                <div className="commaintitleframeviews">47</div>
                <div className="commaintitleframetitle">간만에 가족들과 즐거운 소풍</div>
            </div>
            <div className="comsubtitleframe3">
                <div className="commaintitleframeviews">89</div>
                <div className="commaintitleframetitle">공연 보기전 팁 공유!</div>
            </div>
            <div className="comsubtitleframe4">
                <div className="commaintitleframeviews">52</div>
                <div className="commaintitleframetitle">공연장 옆 음식점 추천!</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunityMainPage;
