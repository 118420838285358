import "./SerchMuseumDetailPage.css";
import navback from "../../img/navback.svg";
import pinmuseum from "../../img/pinmuseum.png";
import seoulmap from "../../img/seoulmap.png";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // useNavigate 추가

const SerchMuseumDetailPage = () => {
    const { facilityId } = useParams(); // URL에서 공연장 ID 가져오기
    const [facilityData, setFacilityData] = useState(null);
    const navigate = useNavigate(); // navigate 추가

    // 공연장 상세 정보 가져오기
    useEffect(() => {
        const fetchFacilityDetail = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/culture/facility/detail/${facilityId}`);
                const data = await response.json();
                setFacilityData(data);
            } catch (error) {
                console.error("Error fetching facility detail:", error);
            }
        };

        fetchFacilityDetail();
    }, [facilityId]);

    // "공연 조회" 버튼 클릭 시 SerchMuseumPerformPage로 이동
    const handleViewPerformances = () => {
        navigate(`/serchmuseumperformpage/${facilityId}`);
    };

    if (!facilityData) {
        return <div>Loading...</div>; // 데이터가 없으면 로딩 메시지
    }

    return (
        <div className="sermusdetmainframe">
            <div className="sermusdetmapframe">
                <img className="sermusdetmainmap-icon" alt="" src={seoulmap} />
                <div className="sermusdetdesframe">
                    <div className="sermusdetsimtxt">
                        <p className="p">
                            <b>{facilityData.fcltynm || "시설명 없음"}</b>
                        </p>
                        <p className="p1">{facilityData.adres || "주소 없음"}</p>
                    </div>
                </div>
                <img className="sermusdetmuspin-icon" alt="" src={pinmuseum} />
            </div>
            <div className="sermusdettxtframe">
                <b className="sermusdetnametxt">{facilityData.fcltynm || "시설명 없음"}</b>
                <div className="sermusdetpnumtxt">전화 : {facilityData.telno || "전화 정보 없음"}</div>
                <div className="sermusdetdestxt">{`위치 : ${facilityData.adres || "위치 정보 없음"}`}</div>
                <div className="sermusdetseatstxt">좌석규모 : {facilityData.seatcnt || "좌석 정보 없음"}</div>
                <div className="sermusdetalignline" />
                {/* 공연 조회 버튼 추가 */}
                <div className="sermusdetperbutton" onClick={handleViewPerformances}>
                    <div className="sermusdetperbuttonframe">
                        <b className="sermusdetperbuttontxt">공연 조회</b>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SerchMuseumDetailPage;
