import './RebuildPeopleAfter.css';
import navback from '../../img/navback.svg';

const RebuildPeopleAfter = () => {
    return (
        <div className="rebpeoaftermainframe">
            <div className="rebpeoafterframe">
                <div className="rebpeoaftertxt2">메인으로 돌아가는 중.....</div>
                <div className="rebpeoaftertxt1">정보 수정이 완료되었습니다!</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RebuildPeopleAfter;
