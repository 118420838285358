import './Logouted.css';
import navback from '../../img/navback.svg';

const Logouted = () => {
    return (
        <div className="logoutedmainframe">
            <div className="logoutedframe">
                <div className="logoutedmaintxt2">이용해주셔서 감사합니다!</div>
                <div className="logoutedmaintxt1">로그아웃 완료!</div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logouted;
