import './Login.css';
import logo from '../../img/logo.svg';
import navback from '../../img/navback.svg';

const Login = () => {
    return (
        <div className="loginframe">
            <div className="loginpagelogininputform">
                <div className="loginpageformbg" />
                <div className="loginpageidbutton">
                    <div className="loginpageidbuttonframe">
                        <b className="loginpageidbuttontxt">ID</b>
                    </div>
                </div>
                <div className="loginpagepwbutton">
                    <div className="loginpageidbuttonframe">
                        <div className="loginpageidbuttontxt">PW</div>
                    </div>
                </div>
                <div className="loginpageloginbutton">
                    <div className="loginpageidbuttonframe">
                        <div className="loginpageloginbuttontxt">로그인</div>
                    </div>
                </div>
                <div className="logininputgroup">
                    <div className="forgotpassword">비밀번호를 잊으셨나요?</div>
                    <div className="loginpagecheck">
                        <div className="loginpagerememberid">아이디 기억하기</div>
                        <div className="loginpagecheckboxshape" />
                    </div>
                </div>
                <div className="loginpageregistertxt">
                    <span className="loginpageregistertxt-txt">
                        <span>{`계정이 없으신가요? `}</span>
                        <span className="span">회원가입</span>
                    </span>
                </div>
            </div>
            <img className="loginmainlogo-icon" alt="" src={logo} />
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
