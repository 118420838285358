import "./SerchDetailPage.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import navback from "../../img/navback.svg";

const SerchDetailPage = () => {
    const { performanceId } = useParams(); // URL에서 performanceId 가져오기
    const [performanceData, setPerformanceData] = useState(null);

    useEffect(() => {
        const fetchPerformanceDetail = async () => {
            try {
                const response = await fetch(`http://co.mayeon.kr:8081/culture/performance/detail/${performanceId}`);
                const data = await response.json();
                console.log(data); // 응답 확인을 위한 콘솔 로그
                setPerformanceData(data.culture);
            } catch (error) {
                console.error("Error fetching performance detail:", error);
            }
        };

        fetchPerformanceDetail();
    }, [performanceId]);

    if (!performanceData) {
        return <div>Loading...</div>; // 데이터가 없으면 로딩 메시지
    }

    return (
        <div className="serdetpermainframe">
            <div className="serdetpersubframe">
                <div className="serdetperdesframe">
                    <b className="serdetpertitletxt">{performanceData.prfnm || "공연 이름 없음"}</b>
                    <div className="serdetpergenretxt">장르: {performanceData.genrenm || "장르 없음"}</div>
                    <div className="serdetperscaletxt">규모: {performanceData.prfstate || "정보 없음"}</div>
                    <div className="serdetperpnumtxt">전화: {performanceData.phone || "정보 없음"}</div>
                    <div className="serdetperperiodtxt">
                        기간: {performanceData.prfpdfrom || "시작일 없음"} - {performanceData.prfpdto || "종료일 없음"}
                    </div>
                    <div className="serdetperdestxt">위치: {performanceData.fcltynm || "위치 정보 없음"}</div>
                    <div className="serdetperruntimetxt">
                        공연 런타임: {performanceData.prfruntime || "런타임 정보 없음"}
                    </div>
                    <div className="serdetperagetxt">관람 연령: {performanceData.prfage || "연령 제한 없음"}</div>
                    <div className="serdetperpricetxt">
                        티켓가격: {performanceData.pcseguidance || "가격 정보 없음"}
                    </div>
                    <div className="serdetperstorytxt">줄거리: {performanceData.story || "줄거리 정보 없음"}</div>
                    <div className="serdetperdesalign" />
                </div>
                <img className="serdetperimg-icon" alt="" src={performanceData.poster || "기본 이미지"} />
            </div>
            <div className="serdetperbuttonframe">
                <div className="serdetperfecbutton">
                    <div className="serdetperfecbuttonframe">
                        <b className="serdetperfecbuttontxt">주변 편의시설 조회</b>
                    </div>
                </div>
                <div className="serdetpersavebutton">
                    <div className="serdetperfecbuttonframe">
                        <b className="serdetperfecbuttontxt">찜하기</b>
                    </div>
                </div>
            </div>
            <div className="navbar">
                <img className="navback-icon" alt="" src={navback} />
                <b className="logoutbutton">로그아웃</b>
                <b className="mypagebutton">마이페이지</b>
                <div className="navlink">
                    <div className="navlinkhome">
                        <div className="home">Home</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">정보</div>
                    </div>
                    <div className="navlinkhome">
                        <div className="home">커뮤니티</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SerchDetailPage;
